import Swiper, { Pagination, Mousewheel, EffectFade, Autoplay } from 'swiper';

(function($) {

	if( $('.wordswiper').length !== 0 ) {
    $('.wordswiper').each(function(i, obj) {
      const wordSwiper = new Swiper( obj, {
        // Optional parameters
        modules: [ Pagination, Mousewheel, Autoplay, EffectFade ],
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        autoplay: {
          delay: 1000,
        },
        speed: 100,
        loop: true,
        slidesPerView: 'auto',
        noSwiping: true,
      });
    });
	}

	if( $('.benefits-swiper').length !== 0 ) {
    const benefitsSwiper = new Swiper('.benefits-swiper', {
      // Optional parameters
      modules: [ Pagination, Mousewheel ],
      direction: 'horizontal',
			grabCursor: true,
			autoplay: false,
      mousewheel: {
				forceToAxis: true,
			},
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 0,
    });
	}

	if( $('.swiper-testimonial-images').length !== 0 ) {
    const swiperTestimonialImages = new Swiper('.swiper-testimonial-images', {
      // Optional parameters
      modules: [ Pagination, Mousewheel, EffectFade ],
      direction: 'horizontal',
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        bulletActiveClass: 'active bg-white',
        bulletClass: 'w-4 h-4 transition-colors duration-200 rounded-full border border-solid border-white inline-block',
        renderBullet: function (index, className) {
          return '<button aria-label="Button" role="button" class="' + className + '"><span class="sr-only">' + index + '</span></button>';
        },

      },
			grabCursor: true,
			autoplay: false,
      mousewheel: {
				forceToAxis: true,
			},
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 16,
    });
  }

	if( $('.tile-swiper').length !== 0 ) {
    const swiperTiles = new Swiper('.tile-swiper', {
      // Optional parameters
      modules: [ Pagination, Mousewheel ],
      direction: 'horizontal',
			grabCursor: true,
			autoplay: false,
      mousewheel: {
				forceToAxis: true,
			},
      loop: false,
      slidesPerView: 2,
      spaceBetween: 32,
      breakpoints: {
        320: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 4,
        }
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        bulletActiveClass: 'active bg-aglp-grey-dark',
        bulletClass: 'w-4 h-4 transition-colors duration-200 rounded-full border border-solid border-aglp-grey-dark inline-block',
        renderBullet: function (index, className) {
          return '<button aria-label="Button" role="button" class="' + className + '"><span class="sr-only">' + index + '</span></button>';
        },

      }
    });
	}

	if( $('.top-seller-swiper').length !== 0 ) {
    const swiperTopSeller = new Swiper('.top-seller-swiper', {
      // Optional parameters
      modules: [ Pagination, Mousewheel ],
      direction: 'horizontal',
			grabCursor: true,
			autoplay: false,
      mousewheel: {
				forceToAxis: true,
			},
      loop: false,
      slidesPerView: 2,
      spaceBetween: 32,
      breakpoints: {
        320: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 4,
        }
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        bulletActiveClass: 'active bg-aglp-grey-dark',
        bulletClass: 'w-4 h-4 transition-colors duration-200 rounded-full border border-solid border-aglp-grey-dark inline-block',
        renderBullet: function (index, className) {
          return '<button aria-label="Button" role="button" class="' + className + '"><span class="sr-only">' + index + '</span></button>';
        },

      }
    });
	}


})(jQuery);
