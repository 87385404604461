import Swiper, { Autoplay } from 'swiper';

(function($) {
  if ( $('.swiper-press').length !== 0 ) {
    new Swiper( '.swiper-press',
    {
      modules: [ Autoplay ],
      slidesPerView: 'auto',
      spaceBetween: 0,
      speed: 5000,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
    });
  }
})(jQuery);
