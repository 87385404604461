function initTabs() {
  // Get all tab containers
  const tabEls = document.querySelectorAll(".tabs-el");

  if (!tabEls) return;

  // Loop through tab container
  for (let tab of tabEls) {
    let tabHeadings = tab.querySelectorAll(".tab-heading");
    let tabContent = tab.querySelectorAll(".tab-content");

    // Loop through each heading
    tabHeadings.forEach(heading => {
      // Add the click function to each heading
      heading.addEventListener("click", () => {
        // Loop to set the matching body copy to active, set the rest to inactive
        tabContent.forEach(body => {
          body.classList[
            body.getAttribute("data-tab") === heading.getAttribute("data-tab")
              ? "add"
              : "remove"
          ]("active");
        });

        // Remove the active class from all headings
        tabHeadings.forEach(el => el.classList.remove("active"));

        // Add the active class to the currently clicked heading
        heading.classList.add("active");
      });
    });
  }
}

initTabs();
