let panels = document.querySelectorAll(".panel");

function initCollapse() {
  // Exit the function if no panels's are on page.
  if (!panels) return;

  // Loop through all panels's to add the click listener.
  for (let panel of panels) {
    if ( panel.querySelector( ".expand" ) ) {
      panel.querySelector( ".expand" ).addEventListener( "click", (e) => {
        const accordion = panel.closest( '.accordion' );
        panel.classList.toggle( "open" );
        if ( accordion ) {
          accordion.querySelectorAll( '.panel' ).forEach((el) => el !== panel && el.classList.remove( 'open' ));
        }
      });
    }
  }
}

initCollapse();
