
function offset(el) {
  box = el.getBoundingClientRect();
  docElem = document.documentElement;
  return {
    top: box.top + window.pageYOffset - docElem.clientTop,
    left: box.left + window.pageXOffset - docElem.clientLeft
  };
}

function scrollTop(el, value) {
  if (value === undefined) {
    return el.pageYOffset;
  } else {
    if (el === window || el.nodeType === 9) {
      el.scrollTo(el.pageXOffset, value);
    } else {
      el.pageYOffset = value;
    }
  }
}

const menuToggle = document.getElementById( 'menu-toggle' );
const mobileMenu = document.getElementById( 'mobile-menu' );
const headerLogo = document.getElementById( 'logo-group' );
const header = document.getElementById( 'header' );
if ( menuToggle && mobileMenu ) {
  var windowMenuScrollTop = scrollTop(window);
  menuToggle.addEventListener('click', (e) => {
    if ( header.classList.contains( 'menuOpen') ) {
      header.classList.remove('menuOpen');
      mobileMenu.classList.remove('open');
      document.documentElement.classList.remove('prevent-scrolling-mobile-menu');
      document.body.classList.remove('menuOpen');
      window.Tawk_API.showWidget && window.Tawk_API.showWidget();
      if ( windowMenuScrollTop > 0 ) {
        document.documentElement.scrollTop = windowMenuScrollTop;
      }
    } else {
      windowMenuScrollTop = scrollTop(window);
      header.classList.add('menuOpen');
      mobileMenu.classList.add('open');
      document.documentElement.classList.add('prevent-scrolling-mobile-menu');
      document.body.classList.add('menuOpen');
      window.Tawk_API.hideWidget && window.Tawk_API.hideWidget();
    }
  });

  mobileMenu.querySelectorAll('.menu-item-has-children ').forEach( (item) => {
    item.querySelector(':scope > a').addEventListener('click', (e) => {
      e.preventDefault();
      item.classList.toggle('open');
    });
  });
}
window.Tawk_API = window.Tawk_API || {};
window.Tawk_API.onLoad = function(){
  if ( header.classList.contains( 'menuOpen' ) ) {
    window.Tawk_API.hideWidget();
  }
};

document.body.addEventListener('click', function(e) {
  const bagIcon = document.getElementById('header-bag');
  const bagBackdrop = document.getElementById('minicart-backdrop');
  if ( bagIcon && bagIcon.contains( e.target ) ) {
    e.preventDefault();
    if ( header.classList.contains( 'bagOpen' ) ) {
      header.classList.remove( 'bagOpen' );
    } else {
      resizeCartWidget();
      header.classList.add( 'bagOpen' );
    }
  }
  if ( bagBackdrop && bagBackdrop.contains( e.target ) ) {
    header.classList.remove( 'bagOpen' );
  }
});

document.addEventListener('added_to_cart', function( event ) {
  if ( ! header.classList.contains( 'bagOpen') ) {
    resizeCartWidget();
    header.classList.add( 'bagOpen' );
  }
});

function resizeCartWidget() {
  if ( document.querySelectorAll(".mini-cart-wrapper ul.woocommerce-mini-cart").length > 0 ) {
    var widget = document.querySelector(".mini-cart-widget");
    var cart = document.querySelector(".woocommerce-mini-cart");
    var subtotalEl = document.querySelector(".mini-cart-wrapper .woocommerce-mini-cart__total");
    var buttonsEl = document.querySelector(".mini-cart-wrapper .woocommerce-mini-cart__buttons");
    var atcbEl = document.querySelector(".atcb");
    var height = window.innerHeight;
    var widgetOffset = offset(widget).top;
    var widgetMarginTop = parseInt(getComputedStyle(widget)['marginTop']);
    var windowScrollTop = scrollTop(window);
    var positionCartTop = cart.offsetTop;
    var subtotalHeight = subtotalEl.clientHeight;
    var buttonsHeight = buttonsEl.clientHeight;
    var atcbHeight = 0;
    if ( atcbEl ) {
      atcbHeight = atcbEl.clientHeight;
    }
    // minus = window height - ( widget offset top + widget margin top value ) - window scroll top + cart position top + ( subtotal height + button height )
    var height = window.innerHeight - ( ( widgetOffset + widgetMarginTop ) - windowScrollTop + positionCartTop + ( subtotalHeight + buttonsHeight ) + 40) - atcbHeight;
    cart.style.maxHeight = height + "px";
  }
}

window.addEventListener("resize", function(){
  resizeCartWidget();
});
document.addEventListener('DOMContentLoaded', function(event) {
  resizeCartWidget();
});



function debounce(func, wait, immediate) {
  var timeout;
  return function() {
      var context = this, args = arguments;
      var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
  };
};

function scrollDetect(){
  var header = document.getElementById('header');
  var headerHeight = header.getBoundingClientRect().height;
  var logoGroup = document.getElementById('logo-group');
  var lastScroll = 0;

  onScroll();
  window.addEventListener('scroll', debounce( onScroll, 50 ), false);

  function onScroll() {
      let currentScroll = document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value
      if (currentScroll > 0 && lastScroll <= currentScroll){
        if ( currentScroll >= 150 ) {
          header.classList.add('scrolled');
        }
      } else if ( header.classList.contains('scrolled') && ( currentScroll + headerHeight <= 200 ) ){
          header.classList.remove('scrolled');
      }
      lastScroll = currentScroll;
  };
}

scrollDetect();


