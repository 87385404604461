import Swiper, { Navigation, EffectFade } from 'swiper';
import { loadTooltips } from './tooltips';

(function($) {

  $('form.cart').on('submit', function(e) {
    if ( $(this).data('switch') == 'yes' ) {
      return;
    }
    e.preventDefault();

    var form = $(this);
    form.block({ message: null, overlayCSS: { background: '#fff', opacity: 0.6 } });

    var formData = new FormData(form[0]);
    formData.append('add-to-cart', form.find('[name=add-to-cart]').val() );

    // Ajax action.
    $.ajax({
      url: wc_add_to_cart_params.wc_ajax_url.toString().replace( '%%endpoint%%', 'ace_add_to_cart' ),
      data: formData,
      type: 'POST',
      processData: false,
      contentType: false,
      complete: function( response ) {
        response = response.responseJSON;

        if ( ! response ) {
          return;
        }

        if ( response.error && response.product_url ) {
          window.location = response.product_url;
          return;
        }

        // Redirect to cart option
        if ( wc_add_to_cart_params.cart_redirect_after_add === 'yes' ) {
          window.location = wc_add_to_cart_params.cart_url;
          return;
        }

        // Trigger event so themes can refresh other areas.
        $( document.body ).trigger( 'added_to_cart', [ response.fragments, response.cart_hash ] );
        // Vanilla JS so we can trigger based on added_to_cart outside of jquery.
        const event = new CustomEvent('added_to_cart', [ response.fragments, response.cart_hash ] );
        document.dispatchEvent(event);

        // Remove existing notices
        $( '.woocommerce-error, .woocommerce-message, .woocommerce-info' ).remove();

        // Add new notices
        form.closest('.product').before(response.fragments.notices_html)

        form.unblock();
      }
    });
  });


  $(document).on('click', '[data-product_tab]', (function() {
    var tab = $(this);
    var num = $(this).data("product_tab");
    var panel = $('[data-product_panel="' + num + '"]');
    var atcbar_html = $(this).html();
    if ( panel.length > 0 ) {
      $("[data-product_panel]").not(panel).removeClass("active"),
      panel.addClass("active"),
      $('[data-product_tab]').not($('[data-product_tab="' + num + '"]')).removeClass("active"),
      $('[data-product_tab="' + num + '"]').addClass("active"),
      $("#atcbsp").html(atcbar_html);
      loadTooltips();
      if (panel.parents(".aglp-product").length) {
        var url = $(this).attr("href");
        $(this).parents(".aglp-product").find(".woocommerce-LoopProduct-link, .product-loop-permalink").attr("href", url);
      }
      return false;
    }
  }));

  $(document).on('click', '[data-product_tab]', (function() {
    var tab = $(this);
    var num = $(this).data("product_tab");
    var panel = $('[data-product_panel="' + num + '"]');
    var atcbar_html = $(this).html();
    if ( panel.length > 0 ) {
      $("[data-product_panel]").not(panel).removeClass("active"),
      panel.addClass("active"),
      $('[data-product_tab]').not($('[data-product_tab="' + num + '"]')).removeClass("active"),
      $('[data-product_tab="' + num + '"]').addClass("active"),
      $("#atcbsp").html(atcbar_html);
      if ( panel.parents(".aglp-product").length) {
          var a = $(this).attr("href");
          $(this).parents(".product").find(".woocommerce-LoopProduct-link, .aglp-product .product-loop-permalink").attr("href", a)
      }
      return false;
    }
  }));

  $(document).on('ready', function() {
    var activeOpt = $('.buy-btn.active');
    if ( activeOpt.length > 0 ) {
      var atcbar_html = $(activeOpt).html();
      if ( atcbar_html ) {
        $("#atcbsp").html(atcbar_html);
      }
    }
  });

  $(document).on( "click", ".wc-quantity-wrapper button.plus, .wc-quantity-wrapper button.minus", ( function() {
    var qty = $(this).closest(".wc-quantity-wrapper").find(".qty");
    var currentQty = parseFloat(qty.val()) || 0;
    var max = parseFloat(qty.attr("max"));
    var min = parseFloat(qty.attr("min"));
    var step = parseFloat(qty.attr("step"));
    $(this).is(".plus") ? max && max <= currentQty ? qty.val(max) : qty.val(currentQty + step) : min && min >= currentQty ? qty.val(min) : currentQty > 0 && qty.val(currentQty - step),
    $(".input-text.qty.text").change()
  } ) );


  const swiper = new Swiper('.product-gallery', {
    // Optional parameters
    modules: [ EffectFade, Navigation ],
    direction: 'horizontal',
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
  
    // Navigation arrows
    navigation: {
      nextEl: '.slider-controls-next',
      prevEl: '.slider-controls-prev',
    },
  });

  swiper.on('realIndexChange', function () {
    var index = swiper.realIndex;
    var thumbs = $('[data-thumb]');
    var thumb = $(`[data-thumb='${index}']`);
    thumbs.removeClass('active');
    thumb.addClass('active');
  })

  swiper.on('autoplayPause', function () {
    $('.slider-controls-dots').addClass('paused');
  } );

  swiper.on('autoplayResume', function () {
    $('.slider-controls-dots').removeClass('paused'); 
  } );

  $('.product-gallery .gallery-slide a').on('click', function (e) {
    e.preventDefault();
  });


  $(document).on('click', '[data-thumb]', function (e) {
    var id = this.getAttribute('data-thumb');
    swiper.slideToLoop(id);
  });

  //Make sure we load tooltips again on variation change!
  jQuery( 'form.variations_form' ).on('woocommerce_variation_has_changed', () => loadTooltips() );
  jQuery( 'form.variations_form' ).on('wc_variation_form', () => loadTooltips() );
  
})(jQuery);
