import Swiper, { Navigation, Pagination, Autoplay, Mousewheel } from 'swiper';

(function($) {
  if ( $('.testimonial-swiper').length !== 0 ) {
    // DOM ready, take it away

    const swiper = new Swiper('.testimonial-swiper', {
      // Optional parameters
      modules: [ Navigation, Pagination, Autoplay, Mousewheel ],
      direction: 'horizontal',
      loop: false,
      slidesPerView: 'auto',
      grabCursor: true,
      mousewheel: {
				forceToAxis: true,
			},
      spaceBetween: 16,
      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
    });

  }

})(jQuery);