import {
  popperGenerator,
  defaultModifiers,
} from '@popperjs/core/lib/popper-lite';
import flip from '@popperjs/core/lib/modifiers/flip';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';

const createPopper = popperGenerator({
  defaultModifiers: [...defaultModifiers, flip, preventOverflow],
});

export const loadTooltips = () => {
  const tooltips = document.querySelectorAll('.tooltip');
  tooltips.forEach(tooltip => {
    const button = tooltip.querySelector('.tooltip-button');
    const content = tooltip.querySelector('.tooltip-content');
    var popperInstance = createPopper(button, content, {
      placement: 'auto',
    });

    function show() {
      // Make the tooltip visible
      button.setAttribute('data-show', '');
      content.setAttribute('data-show', '');
      // Enable the event listeners
      popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: true },
        ],
      }));

      // Update its position
      popperInstance.update();
    }
    function hide() {
      // Hide the tooltip
      button.removeAttribute('data-show');
      content.removeAttribute('data-show');
      // Disable the event listeners
      popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: false },
        ],
      }));
    }

    const showEvents = ['mouseenter', 'focus'];
    const hideEvents = ['mouseleave', 'blur'];
    showEvents.forEach((event) => {
      button.addEventListener(event, show);
    });

    hideEvents.forEach((event) => {
      button.addEventListener(event, hide);
    });
  });
}

loadTooltips();