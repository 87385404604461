

if ('IntersectionObserver' in window) {
  var options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1
  };
  var stickyBarObserver = new IntersectionObserver(function (entries, observer) {
    if (!entries[0].isIntersecting) {
      document.body.classList.add('atcba');
    }
    else {
      document.body.classList.remove('atcba');
      handleDropdownOpenState();
    }
  }, options);

  var stickyBarElement = document.querySelector('.atcst');
  if (stickyBarElement) {
    stickyBarObserver.observe(stickyBarElement);
  }
}

(function($) {
  handleDropdownOpenState = function() {
    if ( document?.body?.classList.contains('atcdd--open') ) {
      // dropdown.classList.add('dba');
      document?.body?.classList.remove('atcdd--open');
      document?.body?.classList.remove('overlay--active');
    }
  }

  handleDropdownCloseState = function() {
    // var dropdownWrapper = ('#atcddw');
    if ( ! document?.body?.classList.contains('atcdd--open') ) {
      // dropdown.classList.remove('dba');
      document?.body?.classList.add('atcdd--open');
      document?.body?.classList.add('overlay--active');
    }
  }

  handleDropdownToggleState = function() {
    if ( ! document?.body?.classList.contains('atcdd--open') ) {
      // dropdown.classList.add('dba');
      document?.body?.classList.add('atcdd--open');
      document?.body?.classList.add('overlay--active');
    } else {
      // dropdown.classList.remove('dba');
      document?.body?.classList.remove('atcdd--open');
      document?.body?.classList.remove('overlay--active');
    }
  }

  // $('#atcbddw')

  $('#atcbddw.active').on( 'mouseenter', handleDropdownCloseState );
  $('#atcbddw.active').on( 'mouseleave', handleDropdownOpenState );
  $('#atcbddw.active').on( 'click', handleDropdownToggleState );

})(jQuery);