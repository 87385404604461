import counterUp from 'counterup2'


const callback = entries => {
	entries.forEach( entry => {
		const el = entry.target
		if ( entry.isIntersecting && ! el.classList.contains( 'is-visible' ) ) {
			counterUp( el, {
				duration: 1500,
				delay: 16,
			} )
			el.classList.add( 'is-visible' )
      el.classList.add( 'opacity-100' )
		}
	} )
}

const IO = new IntersectionObserver( callback, { threshold: 1 } )

const counters = document.querySelectorAll( '.num-counter' )
counters.forEach( el => IO.observe( el ) )